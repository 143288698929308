<div class="media-processing" (dragenter)="dragDropOverlay.dragDidStart($event)">
    <div class="media-processing__header">
        <div class="media-processing__title">
            <button class="media-processing__close-btn" (click)="close()">
                <vwui-icon iconName="back" class="media-processing__close-icon"></vwui-icon>
            </button>
            <span class="media-processing__title-label">{{modalTitles[type]}}</span>
            <div class="media-processing__count" *ngIf="mediaItems.length > 0">{{selectedMediaItems.length}}</div>
        </div>
        <div class="media-processing__actions">
            <vwui-checkbox
                [disabled]="mediaItems.length === 0"
                [checked]="allMediaItemsSelected"
                [indeterminate]="someMediaItemsSelected"
                (click)="toggleMediaItemsSelection()"
                class="media-processing__toggle-selection"
            ></vwui-checkbox>
            <vwui-button
                *ngIf="this.type !== 'approve'"
                class="is-neutral"
                icon="trash"
                [disabled]="selectedMediaItems.length === 0"
                [loading]="deletingMedia"
                (click)="deleteSelectedMediaItems()"
            ></vwui-button>
            <vwui-button
                *ngIf="this.type !== 'approve'"
                class="is-secondary"
                [loading]="savingMedia"
                (click)="linkTagsAndDocumentToMedia(false)"
            >Opslaan</vwui-button>
            <vwui-button
                class="is-primary media-processing__process-button"
                icon="upload"
                [disabled]="!canProcessSelection()"
                [loading]="processingMedia"
                (click)="linkTagsAndDocumentToMedia(true)"
            >
                <span class="media-processing__process-label">Selectie verwerken</span>
            </vwui-button>
        </div>
    </div>
    <div class="media-processing__container">
        <div class="media-processing__sidebar">
            <app-media-processing-sidebar
                [project]="project"
                [albums]="albums"
                [hasMediaItems]="mediaItems.length > 0"
                [selectedMediaItems]="selectedMediaItems"
                [isApproval]="type === 'approve'"
                [isExternal]="type === 'external' || this.externalUserService.isExternalUser()"
                (update)="onMediaDataUpdate($event)"
                (itemSelected)="onMediaItemSelected($event)"
            ></app-media-processing-sidebar>
        </div>
        <div class="media-processing__content">
            <app-media-processing-content
                *ngIf="init"
                [showUpload]="type !== 'approve'"
                [loading]="loadingMedia"
                [mediaItems]="mediaItems"
                (itemSelected)="onMediaItemSelected($event)"
                (upload)="onFileUpload($event)"
            ></app-media-processing-content>
        </div>
    </div>
</div>

<app-drag-drop-overlay #dragDropOverlay [allowUpload]="type !== 'approve'" (filesDropped)="onFileUpload($event)"></app-drag-drop-overlay>
<app-progress-bar
    [showProgressBar]="showUploadProgress"
    [imageProgressBarCount]="uploadProgressCounter"
    [pbTotalCount]="uploadProgressTotalCount"
    [fileName]="uploadCurrentFilename"
    [totalSize]="uploadTotalBytes"
    [uploadProgress]="uploadProgress"
    (cancel)="onUploadCancel()"
></app-progress-bar>
