import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {VwuiModalConfig, VwuiModalRef, VwuiModalService} from '@recognizebv/vwui-angular';
import {Media} from '../../models/media';
import {ProjectService} from '../../services/project.service';
import {ToastrService} from 'ngx-toastr';
import {HasPermission} from '../../models/project-download-permission';
import {FormControl, FormGroup} from '@angular/forms';
import {ImageDownloadThumbnail} from '../image-download-media/image-download-media.component';
import {ImageEditorComponent} from '../image-editor/image-editor.component';
import {MsalService} from '@azure/msal-angular';
import {DeleteMediaComponent} from '../delete-media/delete-media.component';
import {Project} from '../../models/project';
import {MediaService} from '../../services/media.service';
import {map, take} from 'rxjs/operators';
import {ProjectDownloadPermissionService} from '../../services/project-download-permission.service';
import {FileUtil} from '../../shared/file';
import {ProjectAlbum} from '../../models/project-album';
import {FeatureToggle} from '../../models/feature-toggle';
import {VistaCreateService} from '../../services/vista-create.service';
import {PublishedDesign} from '../../models/vista/published-design';
import {scaleResolution} from '../utils/scale-resolution';
import {
    PRIMARY_OUTLET,
    Router,
    UrlSegment, UrlTree,
} from '@angular/router';
import {HttpErrorResponse} from '@angular/common/http';

@Component({
    selector: 'app-image-modal',
    templateUrl: './image-modal.component.html',
})
export class ImageModalComponent implements OnInit {

    @ViewChild('videoPlayer') videoplayer: ElementRef<HTMLVideoElement>;

    displayAllMedia: Media[] = [];
    selectedMedia: Media;
    loadingThumbnails = true;
    loading = false;
    hasPermissionRes: HasPermission;
    editTag = 0;
    form: FormGroup;
    downloadLoading = false;
    private album: ProjectAlbum | null;
    vistaToggle = FeatureToggle.VistaCreate;
    vistaPublished = false;
    isExternalUser = false;

    constructor(
        public modalRef: VwuiModalRef,
        public modalConfig: VwuiModalConfig<{ selected: Media, inMemoryImages: Media[], album: ProjectAlbum | null, vistaPublished: (data: PublishedDesign, media: Media) => void, vistaClosed: (media: Media) => void }>,
        private projectService: ProjectService,
        private projectDownloadPermissionService: ProjectDownloadPermissionService,
        private toast: ToastrService,
        private authService: MsalService,
        private modalService: VwuiModalService,
        private mediaService: MediaService,
        private vistaService: VistaCreateService,
        private router: Router
    ) {
        this.selectedMedia = this.modalConfig.data.selected as Media;
        this.album = this.modalConfig.data.album;

        let tags = null;
        if (this.selectedMedia && this.selectedMedia.tags) {
            tags = this.selectedMedia.tags;
        }
        this.form = new FormGroup({
            tags: new FormControl(tags)
        });
    }

    async ngOnInit() {
        this.isExternalUser = this.checkIfRouteIsExternal();

        await this.hasPermission(this.selectedMedia.project.id);
        if (this.selectedMedia.fromType === 'media' || !this.selectedMedia.fromType) {
            this.displayAllMedia = this.modalConfig.data.inMemoryImages;
        } else if (this.selectedMedia.fromType === 'tag') {
            await this.tagRelatedMedia(this.selectedMedia.tags);
        }

        setTimeout(() => this.loadingThumbnails = false, 1000);
    }

    async startVista() {
        try {
            const {url} = await this.mediaService.fetchMedia(this.selectedMedia.id).toPromise()
            const media = {...this.selectedMedia, url}
            this.vistaService.startVista(
                media,
                async (data: PublishedDesign) => {
                    this.vistaPublished = true;
                    this.modalConfig.data.vistaPublished(data, this.selectedMedia);
                },
                () => {
                    if (!this.vistaPublished) {
                        this.modalConfig.data.vistaClosed(this.selectedMedia);
                        this.vistaPublished = false
                    }
                }
            )
        } catch (exception) {
            this.toast.error(exception.message)
        }

        this.modalRef.close()
    }

    async hasPermission(projectId) {
        try {
            this.hasPermissionRes = await this.projectService.getPermissionDetails(
                projectId, this.album ? this.album.id : null
            ).toPromise();
        } catch (err) {
            console.error('Error occurred while getting the values', err);
        }
    }

    private checkIfRouteIsExternal() {
        const urlTree: UrlTree = this.router.parseUrl(this.router.url);
        const routeSegments: UrlSegment[] = urlTree.root.children[PRIMARY_OUTLET]?.segments;

        return routeSegments?.[0]?.path === 'external' || false;
    }

    updateSelectedImage(image: Media) {
        image.isSelected = !image.isSelected;
    }

    selectedMediaCount() {
        return this.displayAllMedia.filter(it => it.isSelected).length;
    }

    async tagRelatedMedia(tags) {
        const reqTags = tags.map(tagobj =>
            tagobj.tag.name);

        try {
            // TODO: Pagination
            const res = await this.mediaService.getList(0, 25, {
                tagName: reqTags
            }).pipe(map(it => it.content)).toPromise();
            this.displayAllMedia = res;
            this.displayAllMedia = res.map(file => ({...file, isSelected: false}));
        } catch (err) {
            console.error('Error occurred while getting the list', err);
        }
    }

    async downloadMedia() {
        const selected = this.displayAllMedia
            .filter(item => item.isSelected);

        if (selected.length === 0) {
            if (!this.selectedMedia) {
                return;
            }

            selected.push(this.selectedMedia);
        }
        const mediaIds = selected
            .map(id => id.id);

        try {
            this.downloadLoading = true;
            if (mediaIds.length > 1) {
                const toast = this.toast.info('Nog even geduld. We zijn voor u de bestanden aan het downloaden.', null, {disableTimeOut: true});
                const blob = await this.mediaService.downloadMultiple(mediaIds);
                FileUtil.downloadBlobAsFile(blob, selected.length > 1 ? `download-media.zip` : selected[0].fileName);
                this.toast.clear(toast.toastId);
            } else {
                const {url} = await this.mediaService.fetchMedia(mediaIds[0]).toPromise();
                window.open(url, '_blank', 'noopener,noreferrer');
            }
        } catch (error) {
            console.error('media download failed', error);
            if (error instanceof HttpErrorResponse && error.status === 429) {
                this.toast.error('Op dit moment kan het downloaden niet worden uitgevoerd. Probeer het later nog eens.');
            } else {
                this.toast.error('Media downloaden mislukt');
            }
        } finally {
            this.downloadLoading = false;
        }
    }

    async downloadThumbnail({groupLabel, thumbnail}: { groupLabel: string; thumbnail: ImageDownloadThumbnail }) {
        this.modalService.open(ImageEditorComponent, {
            data: {
                image: this.selectedMedia,
                download: {
                    groupLabel,
                    width: thumbnail.width,
                    height: thumbnail.height,
                    label: thumbnail.label
                }
            },
            modalClass: 'preview-modal__modal'
        });
    }

    async downloadScaled({media, scale}: { media: Media; scale: number }) {
        const { width, height } = scaleResolution({width: media.width, height: media.height}, scale);
        try {
            this.downloadLoading = true;
                const blob = await this.mediaService.fetchScaledImage(media, width, height).toPromise();
                FileUtil.downloadBlobAsFile(blob, media.fileName);
        } catch (e) {
            this.toast.error('Media downloaden mislukt: ' + e.message);
            console.error('media download failed', e);
        } finally {
            this.downloadLoading = false;
        }
    }

    async toggleVideo() {
        await this.videoplayer.nativeElement.play();
    }

    selectedThumb(imgObj) {
        this.selectedMedia = imgObj;
    }

    requestPermission(project: Project) {
        this.loading = true;
        this.projectDownloadPermissionService.createPermissionRequest(project.id.toString()).subscribe(() => {
            this.loading = false;
            this.hasPermission(this.selectedMedia.project.id);
            this.toast.success('Verstuurd');
        }, err => {
            this.loading = false;

            console.error(err + 'Error occured while creating project');
            this.toast.error('Versturen mislukt');
        });
    }

    get canPlayVideo() {
        return this.videoplayer && this.selectedMedia && this.selectedMedia.video
            && this.videoplayer.nativeElement.canPlayType(this.selectedMedia.fileType);
    }

    edit() {
        const modal = this.modalService.open(ImageEditorComponent, {
            data: {
                image: this.selectedMedia,
            },
            modalClass: 'preview-modal__modal'
        });
        modal.afterClosed.pipe(take(1)).subscribe(result => {
            if (result === 'copied') {
                this.mediaService.mediaUpdated$.next();
                this.modalRef.close();
            }
        });
    }

    delete() {
        const modal = this.modalService.open(DeleteMediaComponent, {
            data: {
                media: this.selectedMedia,
            }
        });
        modal.afterClosed.subscribe(result => {
            if (result.message === 'SUCCESS') {
                this.mediaService.mediaUpdated$.next();
                this.modalRef.close();
            }
        });
    }

    onTagsSaved(media: Media) {
        this.selectedMedia = {...media};
        this.mediaService.mediaUpdated$.next();
    }
}
