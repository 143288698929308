import {Component, EventEmitter, forwardRef, Input, Output} from '@angular/core';
import {ControlValueAccessor, FormBuilder, FormControl, NG_VALUE_ACCESSOR} from '@angular/forms';
import {take} from 'rxjs/operators';
import {MediaService} from '../../services/media.service';
import {FileType, fileTypeColors} from '../../models/file-type';

export type ChangeFn = (value: string) => void;
@Component({
    selector: 'app-file-type-filter',
    templateUrl: './file-type-filter.component.html',
    providers: [
        {
            multi: true,
            useExisting: forwardRef(() => FileTypeFilterComponent),
            provide: NG_VALUE_ACCESSOR,
        }
    ]
})
export class FileTypeFilterComponent implements ControlValueAccessor {
    @Input() public name = '';

    fileTypes: FileType[];
    filterControl: FormControl;

    onChange: ChangeFn = () => null;
    onTouched: ChangeFn = () => null;

    constructor(private fb: FormBuilder, private mediaService: MediaService) {
        this.createControl();
        this.displayAllFileTypes();
    }

    displayAllFileTypes() {
        this.mediaService.getAllFileTypes().pipe(take(1)).toPromise().then(data => {
            this.fileTypes = data.filter(val => {
                if (val.fileType) {
                    return val.fileValue = val.fileType.split('/')[1].toUpperCase();
                } else {
                    return false;
                }
            });
        }, err => {
            console.error('Error While getting the list!', err);
        });
    }

    public registerOnChange(fn: ChangeFn): void {
        this.onChange = fn;
    }

    public registerOnTouched(fn: ChangeFn): void {
        this.onTouched = fn;
    }

    public writeValue(value: string): void {
        this.filterControl.patchValue(value);
    }

    private createControl() {
        this.filterControl = this.fb.control(null);

        this.filterControl.valueChanges.subscribe(value => {
            this.onTouched(value);
            this.onChange(value);
        });
    }

    fileExtension(fileName: string): string {
        return (fileName || '').toLowerCase().split('.').slice(-1)[0];
    }

    fileTypeColor(fileName: string): string {
        return fileTypeColors[this.fileExtension(fileName)] || fileTypeColors.default;
    }
}
