import {Component, EventEmitter, Input, Output} from '@angular/core';
import {VwuiModalService} from '@recognizebv/vwui-angular';
import {BehaviorSubject, Observable} from 'rxjs';
import {ImageModalComponent} from '../image-modal/image-modal.component';
import {Media} from '../../models/media';
import {PageResponse} from '../../models/page-response';
import {ExternalUserService} from '../../services/external-user.service';
import {ProjectAlbum} from '../../models/project-album';
import {PublishedDesign} from "../../models/vista/published-design";
import {VistaCreateService} from "../../services/vista-create.service";
import {ToastrService} from "ngx-toastr";

@Component({
    selector: 'app-project-media-grid',
    templateUrl: './project-media-grid.component.html'
})
export class ProjectMediaGridComponent {
    @Input() mediaDetails$: Observable<PageResponse<Media>>;
    @Input() allowAddMedia = false;
    @Input() addMediaButtonText = 'Media toevoegen';
    @Input() addMediaDescriptionText = 'Klik hier om media toe te voegen';
    @Input() album: ProjectAlbum|null = null;
    @Input() refresh$: BehaviorSubject<void>;

    @Output() addMedia = new EventEmitter();

    showUploadProgress = false;
    uploadProgress = 0;
    uploadProgressCounter = 1;
    uploadProgressTotalCount = 1;
    uploadCurrentFilename: string;
    uploadTotalBytes = 0;

    constructor(
        private modalService: VwuiModalService,
        private externalUserService: ExternalUserService,
        private vistaService: VistaCreateService,
        private toast: ToastrService,
    ) { }

    openImagePreviewModal(media: Media, mediaItems: Media[]) {
        this.modalService.open(ImageModalComponent, {
            data: {
                selected: media,
                inMemoryImages: JSON.parse(JSON.stringify(mediaItems)),
                album: this.album,
                vistaPublished: async (data: PublishedDesign, media: Media) => {
                    try {
                        this.showUploadProgress = true;

                        const imageData = await fetch(data.url);
                        const blob = await imageData.blob();

                        this.uploadCurrentFilename = media.fileName.split('.').slice(0, -1).join('.') + '_VC.' + data.extension;
                        this.uploadTotalBytes = blob.size

                        const newMedia = await this.vistaService.uploadVistaResult(media, this.album, blob, this.uploadCurrentFilename);

                        this.openImagePreviewModal(newMedia, mediaItems);
                        this.refresh$.next();
                    } catch (exception) {
                        this.toast.error(exception.message);
                    } finally {
                        this.refresh$.next();
                        this.showUploadProgress = false;
                    }
                },
                vistaClosed: (media: Media) => {
                    this.openImagePreviewModal(media, mediaItems);
                    this.refresh$.next();
                }
            },
            modalClass: 'preview-modal__modal',
        });
    }
}
