import {FeatureToggle} from "../app/core/models/feature-toggle";

export const environment = {
    apiPrefix: '/api',
    appName: "Kader InFrame",
    appNameShort: "InFrame",
    azureReadApi: `https://recognize.nl/kader-beeldbank-prd/Read`,
    azureClientId: `50ccb1fa-43ac-42c2-b884-bb250ba7e69b`,
    azureLogoutUri: `https://inframe.kader.nl/`,
    azureRedirectUri: `https://inframe.kader.nl/`,
    loginDescription: "Klik hieronder op inloggen om in te loggen met uw InFrame account.",
    maxDocumentSizeMB: 20,
    showLoginShape: true,
    featureToggles: [FeatureToggle.VistaCreate],
    vistaCreateApiKey: '5K32CWP-33HMFCC-HWQFCQT-29DF65C',
    missingRoles: "U heeft nog geen toegang tot InFrame. Neem contact op met uw beheerder.",
    production: true,
    useMock: false,
    applicationScope: 'https://recognize.nl/kader-beeldbank-prd/Read',
    roleLabels: {
        'ROLE_COMMUNICATION_OFFICER': 'Gebruiker',
        'ROLE_ORGANIZATION_ADMIN': 'Organisatie-admin',
        'ROLE_USER_MANAGER': 'Gebruikersbeheerder',
        'ROLE_MARKETING': 'Marketing',
        'ROLE_SUPER_ADMIN': 'IT-admin',
    }
};
