import {Injectable} from '@angular/core';
import {Media} from '../models/media';
import {environment} from '../../../environments/environment';
import {FileUtil} from '../shared/file';
import {ProjectAlbum} from '../models/project-album';
import {MediaService} from './media.service';
import {BlobStorageService} from './blob-storage.service';
import {PublishedDesign} from '../models/vista/published-design';
import {DesignEditorConfig} from '../models/vista/design-editor-config';
import {AuthService} from './auth.service';
import {ToastrService} from 'ngx-toastr';

declare global {
    interface Window {
        CrelloEditor: {
            init(options: DesignEditorConfig): Promise<any>;
        };
    }
}

@Injectable({
    providedIn: 'root'
})
export class VistaCreateService {
    constructor(
        private mediaService: MediaService,
        private blobStorage: BlobStorageService,
        private authService: AuthService,
        private toast: ToastrService,
    ) {
    }

    startVista(
        media: Media,
        publishCallback: (data: PublishedDesign) => void,
        vistaClosedCallback: () => void,
    ) {
        window.CrelloEditor.init({
            apiKey: environment.vistaCreateApiKey,
            customDimensions: {
                width: media.width,
                height: media.height,
                measureUnits: 'px',
            },
            user: {
                // This make's it possible for the user to access the templates they have created in the past.
                externalUserId: this.authService.getUserName()
            },
            lang: 'nl',
            downloadFormat: media.fileType === 'image/png' ? 'png' : 'jpg',
            lockPages: true,
            onPublishAction(data) {
                publishCallback(data);
            },
            onCloseAction() {
                vistaClosedCallback();
            },
        }).then((editorApi) => {
            editorApi.addBackgroundImage({
                image: media.url,
            });
        });
    }

    async uploadVistaResult(media: Media, album: ProjectAlbum, blob: Blob, newFileName: string): Promise<Media> {
        const response = await this.mediaService.prepareUpload({
            fileName: newFileName,
            fileType: media.fileType,
            fileExtension: FileUtil.getFileExtension(media.fileName)
        }).toPromise();

        await this.blobStorage.uploadToBlobStorage(
            response.accountName,
            response.containerName,
            response.media.blobName,
            response.sasToken,
            new File([blob], newFileName)
        ).toPromise()

        await this.mediaService.finishUpload(response.media).toPromise();

        const sr = await this.mediaService.postTagRequest({
            mediaIds: [response.media.id],
            documentIds: [],
            projectId: media.project.id,
            projectAlbumIds: [album?.id].filter(x => x),
            approved: null,
            tagIds: media.tags.map((tag) => tag.id)
        }).toPromise();

        if (sr.approvalRequired) {
            this.toast.success('Het mediabestand is aangeboden ter goedkeuring.');
        } else {
            this.toast.success('Opgeslagen');
        }

        this.mediaService.mediaUpdated$.next();

        return await this.mediaService.get(response.media.id).toPromise();
    }
}
