import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {BsModalRef} from 'ngx-bootstrap/modal';
import {ExternalUserService} from '../../services/external-user.service';
import {ProjectService} from '../../services/project.service';
import {ToastrService} from 'ngx-toastr';
import {BlobStorageService} from '../../services/blob-storage.service';
import {MediaProcessingFormData} from '../media-processing-sidebar/media-processing-sidebar.component';
import {Router} from '@angular/router';
import {NgxUiLoaderService} from 'ngx-ui-loader';
import {FileUtil} from '../../shared/file';
import {map, pairwise, startWith, takeUntil, tap} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {Media} from '../../models/media';
import {TagRequest} from '../../models/tag-request';
import {MediaService} from '../../services/media.service';
import {PrepareUploadResponse} from '../../models/prepare-upload-response';
import {DocumentService} from '../../services/document.service';
import {Document} from '../../models/document';
import {Project} from '../../models/project';
import {ProjectAlbum} from '../../models/project-album';

type ProcessingType = 'external' | 'upload' | 'approve';

const ALLOWED_FILE_TYPES = [
    'PNG',
    'JPEG',
    'AI',
    'TIFF',
    'EPS',
    'SVG',
    'JPG',
    'TIF',
    'PSD',
    'INDD',
    'MPG',
    'AVI',
    'MP4',
    'MPEG-4',
    'WMV',
    'MOV'
];

@Component({
    selector: 'app-media-processing',
    templateUrl: './media-processing.component.html'
})
export class MediaProcessingComponent implements OnInit {
    type: ProcessingType;
    project: Project;
    albums: ProjectAlbum[];
    modalTitles = {
        external: 'Media uploaden (extern)',
        upload: 'Media uploaden',
        approve: 'Media goedkeuren'
    };

    init = false;
    loadingMedia = false;
    deletingMedia = false;
    savingMedia = false;
    processingMedia = false;
    showUploadProgress = false;
    uploadProgress = 0;
    uploadProgressCounter = 1;
    uploadProgressTotalCount: number;
    uploadCurrentFilename: string;
    uploadTotalBytes = 0;
    uploadTotalLoadedBytes = 0;

    mediaItems: Media[] = [];
    selectedMediaItems: Media[] = [];

    allMediaItemsSelected = false;
    someMediaItemsSelected = false;

    mediaMetadata: MediaProcessingFormData;
    pendingUploads: Media[] = [];

    cancelUpload$ = new Subject();
    cancelUploadController: AbortController;
    cancelUpload = false;

    constructor(
        public externalUserService: ExternalUserService,
        private bsModalRef: BsModalRef,
        private projectService: ProjectService,
        private toast: ToastrService,
        private blobStorage: BlobStorageService,
        private cd: ChangeDetectorRef,
        private router: Router,
        private ngxService: NgxUiLoaderService,
        private mediaService: MediaService,
        private documentService: DocumentService
    ) {
    }

    ngOnInit() {
        if (this.type) {
            this.init = true;
            this.getMediaContent(this.type);
        }
    }

    close() {
        if (this.externalUserService.isExternalUser() && !this.externalUserService.hasDownloadPermission()) {
            this.externalUserService.clearExternalUserSession();
            this.router.navigate(['/']);
        }

        this.bsModalRef.hide();
    }

    canProcessSelection() {
        return this.mediaMetadata?.selectedProject
            && (this.type !== 'approve' || this.mediaMetadata.approved !== null);
    }

    onMediaItemSelected(mediaItem: Media) {
        mediaItem.isSelected = !mediaItem.isSelected;

        this.checkMediaItemsSelection();
    }

    async onUploadCancel() {
        this.ngxService.start('upload-cancel');
        this.cancelUpload = true;
        this.cancelUpload$.next();
        this.cancelUploadController.abort();
        this.resetUploadProgress();

        try {
            const promises = this.pendingUploads.map(item => {
                return this.mediaService.delete(item).toPromise();
            });

            await Promise.all(promises);
        } catch (e) {
            console.error('onUploadCancel error', e);
            this.toast.error('Upload annuleren mislukt');
        } finally {
            this.pendingUploads = [];
            this.cancelUpload = false;

            this.ngxService.stop('upload-cancel');
        }
    }

    checkMediaItemsSelection() {
        this.selectedMediaItems = this.mediaItems.filter(item => item.isSelected === true);
        this.allMediaItemsSelected = this.mediaItems.length > 0 ?
            this.mediaItems.every(item => item.isSelected === true) : false;
        this.someMediaItemsSelected = !this.allMediaItemsSelected ? this.mediaItems.some(item => item.isSelected === true) : false;

        if (this.selectedMediaItems.length === 0) {
            this.mediaMetadata = null;
        }
    }

    toggleMediaItemsSelection() {
        let select = true;

        if (this.someMediaItemsSelected) {
            select = true;
        } else if (this.allMediaItemsSelected) {
            select = false;
        }

        this.someMediaItemsSelected = false;
        this.allMediaItemsSelected = select;

        this.selectedMediaItems = this.mediaItems.map(item => {
            item.isSelected = select;

            return item;
        });

        if (!select) {
            this.selectedMediaItems = [];
        }

        return false;
    }

    onMediaDataUpdate(data: MediaProcessingFormData) {
        this.mediaMetadata = data;
        this.cd.detectChanges();
    }

    async deleteSelectedMediaItems() {
        try {
            this.deletingMedia = true;
            await Promise.all(this.selectedMediaItems.map(item => this.mediaService.delete(item).toPromise()));

            this.toast.success('Media verwijderd');

            await this.getMediaContent(this.type);
        } catch (e) {
            console.error('Error while deleting' + e);
            this.toast.error('Verwijderen van 1 of meerdere media items mislukt');
        } finally {
            this.deletingMedia = false;
            this.mediaService.mediaUpdated$.next();
            this.checkMediaItemsSelection();
        }
    }

    async onFileUpload(fileList: FileList) {
        const isValidList = await this.validateFileList(fileList);

        if (isValidList) {
            this.showUploadProgress = true;
            this.uploadProgressTotalCount = fileList.length;
            this.uploadTotalBytes = 0;
            this.pendingUploads = [];
            this.cancelUploadController = new AbortController();
            const finishedUploads = [];

            const preparedUploads = await Promise.all(Array.from(fileList).map(async file => {
                const response = await this.mediaService.prepareUpload({
                    fileName: file.name,
                    fileType: file.type,
                    fileExtension: FileUtil.getFileExtension(file.name)
                }).toPromise();

                this.uploadTotalBytes += file.size;
                this.pendingUploads.push(response.media);
                return {response, file};
            }));

            for (const {response, file} of preparedUploads) {
                try {
                    await this.saveFileToBlobStorage(file, response);
                    finishedUploads.push(await this.mediaService.finishUpload(response.media).toPromise());
                } catch (e) {
                    this.toast.error('Uploaden mislukt, onbekende fout');
                    console.error('Upload failed', e);
                }
            }

            if (!this.cancelUpload) {
                this.resetUploadProgress();
                this.mediaItems = [...finishedUploads, ...this.mediaItems];
                this.mediaService.mediaUpdated$.next();
            }
        }
    }

    // TODO: Split approval from tag/project linking
    async linkTagsAndDocumentToMedia(process: boolean) {
        if (this.selectedMediaItems.length === 0) {
            return;
        }

        this.savingMedia = !process;
        this.processingMedia = process;
        const projectLinkRequests: TagRequest[] = [];
        const hasFile = this.mediaMetadata.files.length > 0;

        let documents = this.mediaMetadata.documents;
        if (hasFile) {
            documents = [...documents, ...(await this.uploadFiles(this.mediaMetadata.files))];
        }

        if (this.type === 'approve') {
            const mediaByProjectId = this.selectedMediaItems.reduce((result, mediaItem) => {
                const projectId = mediaItem.project.id;
                result[projectId] = [...(result[projectId] || []), mediaItem];
                return result;
            }, {} as { [projectId: number]: Media[] });

            for (const projectId of Object.keys(mediaByProjectId)) {
                projectLinkRequests.push({
                    mediaIds: mediaByProjectId[projectId].map(item => item.id),
                    tagIds: this.mediaMetadata.selectedTags?.map(tag => tag.id),
                    projectId: process ? +projectId : null,
                    projectAlbumIds: [],
                    documentIds: documents.map(it => it.id),
                    approved: this.mediaMetadata.approved ? 'approve' : 'reject'
                });
            }
        } else {
            projectLinkRequests.push({
                mediaIds: this.selectedMediaItems.map(item => item.id),
                tagIds: this.mediaMetadata.selectedTags?.map(tag => tag.id),
                projectId: process ? this.mediaMetadata.selectedProject?.id : null,
                projectAlbumIds: process ? this.mediaMetadata.selectedAlbums?.map(album => album.id) : [],
                documentIds: documents.map(it => it.id),
                approved: null
            });
        }

        try {
            const responses = await Promise.all(projectLinkRequests.map(
                request => this.mediaService.postTagRequest(request).toPromise()
            ));

            const approvalRequiredCount = responses.filter(it => it.approvalRequired).length;

            if (approvalRequiredCount > 0) {
                this.toast.success(
                    'Opgeslagen. ' + approvalRequiredCount + ' mediabestand(en) wachten op goedkeuring',
                );
            } else {
                this.toast.success('Opgeslagen');
            }
        } catch (e) {
            console.error('linkTagToMedia', e);
            this.toast.error('Opslaan niet gelukt');
        }

        this.savingMedia = false;
        this.processingMedia = false;
        this.mediaService.mediaUpdated$.next();
        if (process && this.type !== 'external') {
            this.bsModalRef.hide();
        } else {
            this.selectedMediaItems = [];
            this.mediaMetadata = null;
            this.allMediaItemsSelected = false;
            this.someMediaItemsSelected = false;
            await this.getMediaContent(this.type);
        }
    }

    private async uploadFiles(files: { name: string; file: File }[]): Promise<Document[]> {
        return await Promise.all(files.map(({file}) => {
            return this.documentService.uploadFile(file);
        }));
    }

    private async getMediaContent(type: ProcessingType) {
        this.loadingMedia = true;

        try {
            // TODO: implement proper pagination
            this.mediaItems = await (type !== 'approve'
                    ? this.mediaService.getStagedList(0, 1000)
                    : this.mediaService.getPendingList(0, 1000)
            ).pipe(
                map(it => it.content)
            ).toPromise();
        } catch (e) {
            console.error('getMediaContent error', e);
        } finally {
            this.loadingMedia = false;
        }
    }

    private async validateFileList(fileList: FileList) {
        let totalFileSize = 0;
        const notAllowedFiles: string[] = [];

        for (const file of Array.from(fileList)) {
            const ext = file.name.substr(file.name.lastIndexOf('.') + 1);

            if (!ALLOWED_FILE_TYPES.includes(ext.toUpperCase())) {
                notAllowedFiles.push(file.name);
            }
            if (ext.toUpperCase() === 'EPS' && window.FileReader) {
                const bytes = await new Promise<Uint8Array>((resolve, reject) => {
                    const reader = new FileReader();
                    reader.onload = e => resolve(new Uint8Array(e.target.result as ArrayBuffer));
                    reader.onerror = err => reject(err);
                    reader.readAsArrayBuffer(file.slice(0, 4));
                });
                // Check for DOS EPS Binary file signature (unsupported by vips / imaginary)
                if (bytes[0] === 0xC5 && bytes[1] === 0xD0 && bytes[2] === 0xD3 && bytes[3] === 0xC6) {
                    notAllowedFiles.push(file.name);
                }
            }

            totalFileSize += file.size;
        }

        if (notAllowedFiles.length > 0) {
            this.toast.error(
                `De volgende bestand(en) hebben niet ondersteund bestandsformaat: ${notAllowedFiles.join('<br>')}`,
                '',
                {enableHtml: true});
        }

        return notAllowedFiles.length === 0;
    }

    private saveFileToBlobStorage(file: File, response: PrepareUploadResponse) {
        return this.blobStorage.uploadToBlobStorage(
            response.accountName,
            response.containerName,
            response.media.blobName,
            response.sasToken,
            file, {
                abortSignal: this.cancelUploadController.signal
            }
        ).pipe(
            takeUntil(this.cancelUpload$.asObservable()),
            startWith(0),
            pairwise(),
            tap(([previousLoadedBytes, currentLoadedBytes]) => {
                this.updateUploadProgress(file, previousLoadedBytes, currentLoadedBytes);
            }, (err) => {
                throw err;
            }, () => {
                if (!this.cancelUpload) {
                    this.uploadProgressCounter++;
                }
            })).toPromise();
    }

    private updateUploadProgress(file: File, previousLoadedBytes: number, currentLoadedBytes: number) {
        this.uploadCurrentFilename = file.name;
        this.uploadTotalLoadedBytes += currentLoadedBytes - previousLoadedBytes;
        this.uploadProgress = Math.round(this.uploadTotalLoadedBytes / this.uploadTotalBytes * 100);
    }

    private resetUploadProgress() {
        this.showUploadProgress = false;
        this.uploadProgress = 0;

        this.uploadTotalBytes = 0;
        this.uploadTotalLoadedBytes = 0;

        this.uploadCurrentFilename = null;
        this.uploadProgressTotalCount = null;
        this.uploadProgressCounter = 1;
    }
}
