import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {BeeldbankMsalGuard} from './core/guards/beeldbank-msal.guard';
import {UsersListComponent} from './core/pages/users-list/users-list.component';
import {TagsListComponent} from './core/pages/tags-list/tags-list.component';
import {UsersDetailsComponent} from './core/pages/users-details/users-details.component';
import {TagsDetailsComponent} from './core/pages/tag-details/tags-details.component';
import {AdLoginComponent} from './core/components/ad-login/ad-login.component';
import {LayoutComponent} from './core/components/layout/layout.component';
import {ProjectRequestComponent} from './core/components/project-request/project-request.component';
import {DisplayAllProjectsOrgComponent} from './core/components/display-all-projects-org/display-all-projects-org.component';
import {ExternalUserPageComponent} from './core/components/external-user-page/external-user-page.component';
import {MissingRolesComponent} from './core/components/missing-roles/missing-roles.component';
import {ExternalLayoutComponent} from './core/components/external-layout/external-layout.component';
import {ProjectAlbumDetailComponent} from './core/components/project-album-detail/project-album-detail.component';
import {ProjectAlbumResolver} from './core/resolver/project-album.resolver';
import {ProjectDetailComponent} from './core/pages/project-detail/project-detail.component';
import {MediaGridComponent} from './core/components/media-grid/media-grid.component';
import {PublicDataPageComponent} from './core/pages/public-data-page/public-data-page.component';
import {MyProjectsPageComponent} from './core/pages/my-projects-page/my-projects-page.component';
import {ExternalUserGuard} from './core/guards/beeldbank-external-guard';
import {OrganizationListPageComponent} from './core/pages/organization-list/organization-list.component';
import {OrganizationDetailsPageComponent} from './core/pages/organization-details/organization-details.component';
import {AssociationListPageComponent} from "./core/pages/association-list/association-list-page.component";
import {AssociationDetailsPageComponent} from "./core/pages/association-details/association-details.component";

const routes: Routes = [
    {path: 'login', component: AdLoginComponent},
    {path: 'missing-roles', component: MissingRolesComponent},
    {
        path: 'external',
        canActivateChild: [ExternalUserGuard],
        component: ExternalLayoutComponent,
        children: [
            {
                path: ':projectId/upload',
                component: ExternalUserPageComponent,
            },
            {
                path: ':projectId',
                component: ProjectDetailComponent,
                data: {requiresDownloadPermission: true}
            },
            {
                path: ':projectId/albums/:albumId',
                component: ProjectAlbumDetailComponent,
                resolve: {
                    album: ProjectAlbumResolver
                }
            },
            {
                path: ':projectId/albums/:albumId/upload',
                component: ExternalUserPageComponent,
            },
            {
                path: ':projectId/albums/:albumId/:key',
                component: ProjectAlbumDetailComponent,
                resolve: {
                    album: ProjectAlbumResolver
                }
            }
        ]
    },
    {
        path: '',
        canActivateChild: [BeeldbankMsalGuard],
        component: LayoutComponent,
        children: [
            {
                path: '',
                component: MediaGridComponent
            },
            {
                path: 'public',
                component: PublicDataPageComponent
            },
            {
                path: 'my-projects',
                component: MyProjectsPageComponent
            },
            {
                path: 'project-request',
                component: ProjectRequestComponent
            },
            {
                path: 'projects/:projectId',
                component: ProjectDetailComponent,
            },
            {
                path: 'projects/:projectId/albums/:albumId',
                component: ProjectAlbumDetailComponent,
                resolve: {
                    album: ProjectAlbumResolver
                }
            },
            {
                path: 'organizations/:orgId',
                component: DisplayAllProjectsOrgComponent,
            },
            {
                path: 'manage',
                children: [
                    {
                        path: 'organizations',
                        component: OrganizationListPageComponent,
                    },
                    {
                        path: 'organizations/:orgId',
                        component: OrganizationDetailsPageComponent,
                    },
                    {
                        path: 'associations',
                        component: AssociationListPageComponent,
                    },
                    {
                        path: 'associations/:id',
                        component: AssociationDetailsPageComponent,
                    },
                    {
                        path: 'users',
                        component: UsersListComponent
                    },
                    {
                        path: 'users/:userId',
                        component: UsersDetailsComponent
                    },
                    {
                        path: 'tags',
                        component: TagsListComponent
                    }, {
                        path: 'tags/:tagId',
                        component: TagsDetailsComponent
                    },
                ]
            },
            {
                path: '**',
                pathMatch: 'full',
                redirectTo: '',
            },
        ]
    },
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {enableTracing: false, relativeLinkResolution: 'legacy'})],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
