import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {ProjectAlbum} from '../models/project-album';
import {environment} from '../../../environments/environment';
import {Subject} from 'rxjs';
import {filter, startWith, switchMap, tap} from 'rxjs/operators';
import {MediaService} from './media.service';
import {PageResponse} from '../models/page-response';
import {Project} from '../models/project';

@Injectable({
    providedIn: 'root'
})
export class ProjectAlbumService {
    albumMediaUpdated$ = new Subject<ProjectAlbum>();

    constructor(private client: HttpClient, private mediaDetailsService: MediaService) {
    }

    getList(page: number, organization: number = null, query: string = '', size: number = 25) {
        let params = new HttpParams({
            fromObject: {
                page: page.toString(),
                size: size.toString(),
                query
            }
        });
        if (organization) {
            params = params.set('organization', organization.toString());
        }
        return this.client.get<PageResponse<ProjectAlbum>>(`${environment.apiPrefix}/project-albums`, {params});
    }

    getPublicList(page: number, query: string = '', size: number = 25) {
        return this.client.get<PageResponse<ProjectAlbum>>(`${environment.apiPrefix}/project-albums/search/public`, {
            params: {
                page: page.toString(),
                size: size.toString(),
                query
            }
        });
    }

    createAlbum(album: ProjectAlbum) {
        return this.client.post<ProjectAlbum>(`${environment.apiPrefix}/project-albums`, album);
    }

    getAlbum(albumId: string) {
        return this.client.get<ProjectAlbum>(`${environment.apiPrefix}/project-albums/${albumId}`);
    }

    updateAlbum(album: ProjectAlbum) {
        return this.client.put<ProjectAlbum>(`${environment.apiPrefix}/project-albums/${album.id}`, album);
    }

    deleteAlbum(album: ProjectAlbum) {
        return this.client.delete<void>(`${environment.apiPrefix}/project-albums/${album.id}`);
    }

    getMediaIds(album: ProjectAlbum) {
        return this.albumMediaUpdated$.pipe(
            filter(updatedAlbum => updatedAlbum.id === album.id),
            startWith(album),
            switchMap(() => {
                return this.client.get<number[]>(`${environment.apiPrefix}/project-albums/${album.id}/media`);
            })
        );
    }

    updateMedia(album: ProjectAlbum, mediaIds: number[]) {
        return this.client.post<number[]>(
            `${environment.apiPrefix}/project-albums/${album.id}/media`,
            new HttpParams({fromObject: {media: mediaIds.map(it => it.toString())}})
        ).pipe(
            tap(() => this.mediaDetailsService.mediaUpdated$.next()
        ));
    }

    getShareLink(id: number) {
        return this.client.get(`${environment.apiPrefix}/project-albums/${id}/link`, {
            responseType: 'text'
        })
    }

    removeSharedLink(id: number) {
        return this.client.delete<void>(`${environment.apiPrefix}/project-albums/${id}/link`)
    }
}
