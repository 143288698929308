<app-action-button iconName="download" [popover]="popover"></app-action-button>

<ng-template #popover>
    <div class="popover-image-download">
        <ul class="popover-image-download__list">
            <li class="popover-image-download__list-item">
                <button [disabled]="disabled"
                    class="popover-image-download__list-button"
                    (click)="download.emit()"
                >Download orgineel ({{media.width}}x{{media.height}}, {{media.fileSize|fileSize}})</button>
                <ng-container *ngIf="hasThumbnails">
                    <button [disabled]="disabled"
                            class="popover-image-download__list-button"
                            (click)="triggerDownloadScaled(media, 75)"
                    >Download groot ({{ getScaledMediaResolution(media, 75) }}, ~{{getScaledMediaFilesize(media, 75)|fileSize}})</button>
                    <button [disabled]="disabled"
                            class="popover-image-download__list-button"
                            (click)="triggerDownloadScaled(media, 50)"
                    >Download middel ({{ getScaledMediaResolution(media, 50) }}, ~{{getScaledMediaFilesize(media, 50)|fileSize}})</button>
                    <button [disabled]="disabled"
                            class="popover-image-download__list-button"
                            (click)="triggerDownloadScaled(media, 25)"
                    >Download klein ({{ getScaledMediaResolution(media, 25) }}, ~{{getScaledMediaFilesize(media, 25)|fileSize}})</button>
                </ng-container>
            </li>
            <ng-container *ngIf="hasThumbnails">
                <ng-container *ngFor="let group of thumbnails">
                    <li class="popover-image-download__list-header">{{ group.label }}</li>
                    <li class="popover-image-download__list-item" *ngFor="let thumbnail of group.formats">
                        <button
                            class="popover-image-download__list-button"
                            (click)="triggerDownloadThumbnail(group.label, thumbnail)"
                        >{{thumbnail.label}} ({{thumbnail.width}}x{{thumbnail.height}})</button>
                    </li>
                </ng-container>
            </ng-container>
        </ul>
    </div>
</ng-template>
